import React from "react"
import { Box, Grid } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import { navigate } from "gatsby-plugin-intl"
import urlSlug from "url-slug"

const StandPreview = ({
  stand,
  width,
  fuente_nombre_stand_size,
  color_letra_nombre_stand,
}) => {
  const { nombre, imagen_pabellon, title } = stand
  return (
    <div style={{ width: width }} title={title}>
      <Grid item xs sm md lg xl>
        {/* <Link to={`/${urlSlug(nombre)}`} className={link}> */}
        {/*   <Box style={{ background: "background.paper", width: width }}> */}
        <Box
          style={{ background: "background.paper" }}
          onClick={() => navigate(`/${urlSlug(nombre)}`)}
        >
          {imagen_pabellon !== null && imagen_pabellon.localFile !== null ? (
            <GatsbyImage
              image={imagen_pabellon.localFile.childImageSharp.gatsbyImageData}
              alt={nombre}
            />
          ) : (
            ""
          )}
          <h4
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "center",
              fontSize: `${fuente_nombre_stand_size}vw`,
              color: `${color_letra_nombre_stand}`,
            }}
          >
            {nombre}
          </h4>
        </Box>
        {/*  </Link> */}
      </Grid>
    </div>
  )
}
export default StandPreview
