import React, { useState, useEffect } from "react"
import useStands from "../hooks/useStands"
import StandPreview from "./StandPreview"
import StandPreviewLista from "./StandPreviewLista"
import { TextField, InputAdornment, Grid } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"

const ListadoStandsMovil = ({
  filtro,
  width,
  grid_height,
  grid_width,
  grid_top,
  grid_left,
  buscador,
  modo_lista,
}) => {
  const resultado = useStands((filtro = { filtro }))
  const [stands, setStands] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    filtrado()
  }, [search])

  const filtrado = () => {
    setStands(
      resultado.filter(node =>
        node.palabras_claves.toLowerCase().includes(search.toLowerCase())
      )
    )
  }
  // el  buscador poner posiciones a futuro tambien
  return (
    <>
      <Grid
        style={{
          overflow: "auto",
          height: grid_height,
          overflowX: "hidden",
          width: grid_width,
          top: grid_top,
          left: grid_left,
          position: "absolute",
        }}
        container
        direction="row"
        justifyContent="center"
      >
        {buscador.mostrar ? (
          <TextField
            id="search"
            style={{
              background: `${buscador.color_fondo}`,
            }}
            variant="outlined"
            onChange={e => setSearch(e.currentTarget.value)}
            value={search}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          ""
        )}
        {stands.map(stand =>
          modo_lista.mostrar_lista ? (
            <StandPreviewLista
              key={stand.id}
              stand={stand}
              modo_lista={modo_lista}
            />
          ) : (
            <StandPreview key={stand.id} stand={stand} width={width} />
          )
        )}
      </Grid>
    </>
  )
}

export default ListadoStandsMovil
