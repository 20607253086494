import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import { Hidden, Grid } from "@material-ui/core"
import ListadoStands from "./ListadoStands"
import ListadoStandsMovil from "./ListadoStandsMovil"
import React, { useContext } from "react"
import { graphql } from "gatsby"
import { AuthContext } from "../context/auth"
import VolverLogin from "../components/VolverLogin"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Botones from "./Botones"
import BotonesMovil from "../components/BotonesMovil"
import Carteles from "../components/Carteles"
import Videos from "../components/Videos"
import CartelesMovil from "../components/CartelesMovil"

const Pabellones = ({
  data: {
    allStrapiPabellones: { nodes },
    configuracion,
  },
}) => {
  const {
    imagen_fondo,
    nombre,
    stand_width,
    carteles,
    pabellon,
    grid_height,
    grid_width,
    grid_top,
    grid_left,
    buscador,
    modo_lista,
    color_letra_nombre_stand,
    fuente_nombre_stand_size
  } = nodes[0]
  const { user } = useContext(AuthContext)
  const image = getImage(imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)
  return (
    <>
      {user != null ? (
        <Layout>
          <SEO title={nombre} />
          <Hidden xsDown>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              style={{ position: "fixed", top: 0 }}
              className="backimage"
            >
              {carteles.map(cartel => (
                <div
                  key={cartel.id}
                  style={{
                    position: "absolute",
                    left: cartel.left,
                    top: cartel.top,
                    width: cartel.width,
                  }}
                >
                  <Carteles cartel={cartel} />
                </div>
              ))}
              <ListadoStands
                filtro={nombre}
                width={stand_width}
                grid_height={grid_height}
                grid_width={grid_width}
                grid_top={grid_top}
                grid_left={grid_left}
                modo_lista={modo_lista}
                buscador={buscador}
                color_letra_nombre_stand={color_letra_nombre_stand}
                fuente_nombre_stand_size={fuente_nombre_stand_size}
              />
              {pabellon.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                      }}
                    >
                      <Botones boton={items} />
                    </div>
                  ) : items.strapi_component === "videos.video" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                        height: items.height,
                      }}
                    >
                      <Videos video={items} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </BackgroundImage>
          </Hidden>

          {/* INICIO DE VERSION PARA CELULAR */}
          <Hidden smUp>
            {configuracion.logo_movil !== null &&
            configuracion.logo_movil.localFile !== null ? (
              <GatsbyImage
                image={
                  configuracion.logo_movil.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt="cabecera"
              />
            ) : (
              ""
            )}
            {carteles.map(cartel => (
              <Grid item xs={12} key={cartel.id}>
                <CartelesMovil cartel={cartel} />
              </Grid>
            ))}
            <div>&nbsp;</div>
            {pabellon.map(items => (
              <div key={items.id}>
                {items.strapi_component === "botones.boton" ? (
                  <Grid item xs={12} style={{ marginTop: 20, width: "100%" }}>
                    <BotonesMovil boton={items} />
                  </Grid>
                ) : items.strapi_component === "videos.video" ? (
                  <Grid item xs={12}>
                    <Videos video={items} />
                  </Grid>
                ) : (
                  ""
                )}
              </div>
            ))}
            <div>&nbsp;</div>
            <ListadoStandsMovil
              filtro={nombre}
              width={300}
              modo_lista={modo_lista}
              buscador={buscador}
              color_letra_nombre_stand={color_letra_nombre_stand}
              fuente_nombre_stand_size={fuente_nombre_stand_size}
            />
            <div>&nbsp;</div>
          </Hidden>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default Pabellones

export const query = graphql`
  query ($id: String!) {
    configuracion: strapiConfiguracion {
      color_movil
      logo_movil {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allStrapiPabellones(filter: { id: { eq: $id } }) {
      nodes {
        color_letra_nombre_stand
        fuente_nombre_stand_size
        id
        espago
        imagen_cartelera_mostrar
        nombre
        nombre_en
        nombre_pt
        stand_width
        title
        title_en
        title_pt
        grid_height
        grid_width
        grid_top
        grid_left
        buscador {
          mostrar
          top
          left
          color_fondo
        }
        modo_lista {
          color_fondo
          color_fondo_hover
          fuente_size
          fuente_size_hover
          fuente_color
          fuente_color_hover
          mostrar_lista
          mostrar_logo
          mostrar_imagenpabellon
          mostrar_nombre
          mostrar_nombre_pabellon
        }
        imagen_fondo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        carteles {
          mostrar_celular
          ampliar_cartel
          mostrar
          nombre
          proporcion
          left
          id
          tiempo_slideshow
          top
          width
          marco_size
          color_fondo_marco
          slideshow_imagenes {
            id
            imagen {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    width: 400
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            imagen_link
            imagen_link_tipo
          }
        }
        pabellon
      }
    }
  }
`
